// app global css in SCSS form
.q-item,
.text-h1,
.text-h2,
.text-h3,
.text-h4,
.text-h5,
.text-h6,
.text-subtitle1,
.text-subtitle2,
.text-caption {
  color: #4d4d4d;
}
